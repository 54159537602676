import Profile from "../../components/Profile";
import logoW from "../../assets/crewier_logo_w_.svg"
import logoB from "../../assets/crewier_logo_b_.svg"
import { useSelector } from "react-redux";

function Header() {

  const theme = useSelector((state) => state?.theme?.theme);

  return (
    <header>
      <div
        className="nav-box d-none d-xl-grid"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >

{/* {`contact-${theme === "dark" ? "white" : "black"}.svg`} */}


        <img src={theme === "dark" ? logoW : logoB }></img>
        <div className="osi-panel">
          <b>osi</b>&nbsp;panel
        </div>
      </div>
      <Profile />
    </header>
  );
}

export default Header;
