import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Table,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { FormApi } from "../../api/form_api";
import ReactHtmlParser from "react-html-parser";
import Layout from "../../Layout";

import ThemeSwitcher from "../../components/ThemeSwitcher";
import { useSelector } from "react-redux";
const HomeMain = () => {
  const theme = useSelector((state) => state?.theme?.theme);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{top:"30vh",position:"relative"}}> <Layout>
      <main className="home">
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle></CardTitle>

                  <div className="h1">
                    {" "}
                    <b>Focus Websitesi</b> - Yönetim Paneli
                  </div>
               
          
                 
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout></div>
   
  );
};

export default HomeMain;
