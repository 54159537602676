import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const endpoint = "faq";

function FormIndexFaq() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({
    question_tr: "",
    answer_tr: "",
    question_en: "",
    answer_en: "",
    question_fr: "",
    answer_fr: "",
    question_ru: "",
    answer_ru: "",
    btn_text_en: "",
    btn_text_fr: "",
    btn_text_ru: "",
    btn_text_tr: "",
    btn_href: "",
  });
  const [showButtons, setShowButtons] = useState(false);
  const [validation, setValidation] = useState({
    question_tr: true,
    answer_tr: true,
    question_en: true,
    answer_en: true,
    question_fr: true,
    answer_fr: true,
    question_ru: true,
    answer_ru: true,
    btn_text_en: false,
    btn_text_fr: false,
    btn_text_ru: false,
    btn_text_tr: false,
    btn_href: false,
  });

  useEffect(() => {
    if (location.state?.product) {
      setForm(location.state.product);
      setIsEdit(location.state.isEdit || false);
    }
  }, [location.state]);

  const validator = (key, value) => {
    const isValid = value.length > 0 && value !== "--";
    setValidation((prevState) => ({ ...prevState, [key]: isValid }));
    return !isValid;
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const handleFormData = () => {
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("button", showButtons); // Bu değer boolean olmalı
  
    // JSON verisini oluşturup konsola yazdırma
    const jsonData = {};
    for (const [key, value] of formData.entries()) {
      jsonData[key] = value;
    }
    console.log(typeof jsonData.button);
  
    return formData;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    let errorMessages = [];

    // Formun değişip değişmediğini kontrol et
    const originalForm = location.state?.product || {};
    const isFormChanged = JSON.stringify(originalForm) !== JSON.stringify(form);

    if (!isFormChanged) {
        toast.error("Formda hiçbir değişiklik yapılmadı!");
        return; 
    }

    // Buton görünürlüğüne göre validasyonları kontrol et
    Object.entries(validation).forEach(([key]) => {
        // Buton görünürlüğü 'hayır' ise buton metinlerinin validasyonu yapılmasın
        if (!showButtons && key.startsWith("btn_")) return;

        // Validasyon hatalarını biriktir
        if (validator(key, form[key])) {
            isError = true;
            errorMessages.push(`${key.replace(/_/g, " ")} alanı geçersiz!`);
        }
    });

    if (isError) {
        // Tüm hata mesajlarını tek bir toast popup'ında göster
        toast.error(`Düzenlemeler yapılamadı. Hatalar:\n${errorMessages.join("\n")}`, { autoClose: 5000 });
        return;
    }

    const formData = handleFormData();

    try {
        if (isEdit) {
            await formApi.updatePage(formData);
            toast.success("Soru başarıyla güncellendi!", { autoClose: 5000 });
        } else {
            await formApi.addPage(formData);
            toast.success("Soru eklendi..", { autoClose: 5000 });
        }
        setTimeout(() => {
            window.location.href = "/faq";
        }, 2000);
    } catch (error) {
        console.error("API Hatası:", error);
        toast.error("İşlem yapılamadı..", { autoClose: 2500 });
    }
};




  const handleToggleButtons = (value) => {
    setShowButtons(value === "true");
  };

  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">Soru Ekleme</div>
        </Container>
        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <div className="container mt-3 pb-5">
                    <h4>Soru Ekleme Paneli</h4>
                    <hr />
                    <Form onSubmit={handleSubmit}>
                      {Object.keys(form)
                        .filter(
                          (key) =>
                            !key.startsWith("btn_") &&
                            !["_id", "button", "faq_no", "updatedAt", "createdAt"].includes(
                              key
                            )
                        )
                        .map((key) => (
                          <FormGroup key={key}>
                            <br />
                            <Label for={key}>
                              {(() => {
                                switch (key) {
                                  case "question_tr":
                                    return "Soru (TR)";
                                  case "answer_tr":
                                    return "Cevap (TR)";
                                  case "question_en":
                                    return "Soru (EN)";
                                  case "answer_en":
                                    return "Cevap (EN)";
                                  case "question_fr":
                                    return "Soru (FR)";
                                  case "answer_fr":
                                    return "Cevap (FR)";
                                  case "question_ru":
                                    return "Soru (RU)";
                                  case "answer_ru":
                                    return "Cevap (RU)";
                                  case "btn_text_en":
                                    return "Buton (EN)";
                                  case "btn_text_fr":
                                    return "Buton (FR)";
                                  case "btn_text_ru":
                                    return "Buton (RU)";
                                  case "btn_text_tr":
                                    return "Buton (TR)";
                                  case "btn_href":
                                    return "Buton Link";
                                  default:
                                    return key
                                      .replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      );
                                }
                              })()}
                            </Label>
                            {key.startsWith("answer") ? (
                              <CKEditor
                                editor={ClassicEditor}
                                data={form[key]}
                                onChange={(event, editor) =>
                                  onChangeHandler(key, editor.getData())
                                }
                              />
                            ) : (
                              <Input
                              id={key}
                              name={key}
                              placeholder={`${
                                key.includes('question') ? `SORU` : `Cevap`
                              } (${key.split('_')[1].toUpperCase()}) giriniz...`}
                              type="text"
                              onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                              value={form[key]}
                              valid={validation[key]}
                              invalid={!validation[key]}
                            />
                            )}
                          </FormGroup>
                        ))}
                      <FormGroup>
                        <Label for="button_state">Buton Görünürlüğü</Label>
                        <Input
                          type="select"
                          name="button_state"
                          id="button_state"
                          onChange={(e) => handleToggleButtons(e.target.value)}
                        >
                          <option value="false">Hayır</option>
                          <option value="true">Evet</option>
                        </Input>
                      </FormGroup>
                      {showButtons && (
                        <>
                          <FormGroup>
                            <Label for="btn_text_en">Buton (EN)</Label>
                            <Input
                              id="btn_text_en"
                              name="btn_text_en"
                              placeholder="Button text in English..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.btn_text_en}
                              defaultValue={" "}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="btn_text_fr">Buton (FR)</Label>
                            <Input
                              id="btn_text_fr"
                              name="btn_text_fr"
                              placeholder="Button text in French..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.btn_text_fr}
                              defaultValue={" "}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="btn_text_ru">Buton (RU)</Label>
                            <Input
                              id="btn_text_ru"
                              name="btn_text_ru"
                              placeholder="Button text in Russian..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.btn_text_ru}
                              defaultValue={" "}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="btn_text_tr">Buton (TR)</Label>
                            <Input
                              id="btn_text_tr"
                              name="btn_text_tr"
                              placeholder="Button text in Turkish..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.btn_text_tr}
                              defaultValue={" "}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="btn_href">Buton Link</Label>
                            <Input
                              id="btn_href"
                              name="btn_href"
                              placeholder="Button link..."
                              type="text"
                              onChange={(e) =>
                                onChangeHandler(e.target.name, e.target.value)
                              }
                              value={form.btn_href}
                              defaultValue={" "}
                            />
                          </FormGroup>
                        </>
                      )}

                      <Button type="submit" color="primary">
                        {isEdit ? "Güncelle" : "Ekle"}
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexFaq;
