import { lazy, Suspense } from "react";
import { Routes as Switcher, Route, Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import Login from "../pages/Login/Login";

import HomeMain from "../pages/Home/HomeMain";

import FaqMain from "../pages/Faq/FaqMain";
import FormIndexFaq from "../pages/Faq/FormIndexFaq";

import FormIndexBlog from "../pages/Blog/FormIndexBlog";
import BlogMain from "../pages/Blog/BlogMain";
import ContactMain from "../pages/Contact/ContactMain";
import FormIndexContact from "../pages/Contact/FormIndexContact";

import DocumentMain from "../pages/Document/DocumentMain";
import FormIndexDocument from "../pages/Document/FormIndexDocument";


function Routes() {
  const routeConfig = [
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/home",
      component: HomeMain,
    }
    ,
    {
      path: "faq",
      component: FaqMain,
    },
    {
      path: "form-faq",
      component: FormIndexFaq,
    },
    {
      path: "contact",
      component: ContactMain,
    },
    {
      path: "form-contact",
      component: FormIndexContact,
    },

    {
      path: "document",
      component: DocumentMain,
    },
    {
      path: "form-document",
      component: FormIndexDocument,
    },

    {
      path: "form-blog",
      component: FormIndexBlog,
    },
    {
      path: "blog",
      component: BlogMain,
    }
  ];
  return (
    <Switcher>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          element={
            <Suspense fallback={<Loader />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
    </Switcher>
  );
}

export default Routes;
