import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Container, Card, CardTitle, CardBody, Row } from "reactstrap";
import Layout from "../../Layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import { FormApi } from "../../api/form_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "../../Slider";

const dataSources = [
  {
    id: 1,
    dataURL: "https://picsum.photos/seed/1/600",
  },
];

const endpoint = "document";

function FormIndexDocument() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const [form, setForm] = useState({
    doc_no: "",
    title: "",
    title_fr: "",
    title_tr: "",
    title_ru: "",
    desc_fr: "",
    desc_tr: "",
    desc_ru: "",
    desc: "",
    href_en: "",
    href_tr: "",
    href_fr: "",
    href_ru: "",
    img: "",
  });

  const [validation, setValidation] = useState({
    doc_no: "",
    title: "",
    title_fr: "",
    title_tr: "",
    title_ru: "",
    desc_fr: "",
    desc_tr: "",
    desc_ru: "",
    desc: "",
    href_en: "",
    href_tr: "",
    href_fr: "",
    href_ru: "",
    img: "",
  });

  useEffect(() => {
    if (location.state) {
      if (location.state.product) {
        Object.entries(location.state.product).forEach(([key, value]) => {
          onChangeHandler(key, value);
          setValidation((prevState) => ({ ...prevState, img: "T" }));
        });
      }
      setIsEdit(
        location.state.isEdit !== undefined ? location.state.isEdit : false
      );
    }
  }, []);

  const validator = (key, value) => {
    if (value.length === 0 || value === "--" || value === "F") {
      setValidation((prevState) => ({ ...prevState, [key]: "F" }));
      return true;
    } else setValidation((prevState) => ({ ...prevState, [key]: "T" }));
  };

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
    validator(key, value);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    let formData = new FormData();
    formData.append("title", form.title);
    formData.append("desc", form.desc);
    formData.append("img", form.img);
    formData.append("doc_no", form.doc_no);
    formData.append("title_fr", form.title_fr);
    formData.append("title_tr", form.title_tr);
    formData.append("title_ru", form.title_ru);
    formData.append("desc_fr", form.desc_fr);
    formData.append("desc_tr", form.desc_tr);
    formData.append("desc_ru", form.desc_ru);
    formData.append("href_en", form.href_en);
    formData.append("href_tr", form.href_tr);
    formData.append("href_fr", form.href_fr);
    formData.append("href_ru", form.href_ru);

    try {
      const rst = await formApi.addPage(formData);
      toast.success(
        "Dokuman eklendi..",
        ((window.location.href = "/document"), 5000),
        2.5
      );
    } catch (e) {
      toast.error("Dokuman eklenemedi..", 2.5);
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();
    let isError = false;
    Object.entries(validation).forEach(([key, value]) => {
      if (validator(key, value)) isError = true;
    });

    if (isError) {
      toast.error("Düzenlemeler yapılamadı..", 2.5);
      return;
    } else {
      let formData = new FormData();
      formData.append("_id", form._id);
      formData.append("title", form.title);
      formData.append("desc", form.desc);
      formData.append("img", form.img);
      formData.append("doc_no", form.doc_no);
      formData.append("title_fr", form.title_fr);
      formData.append("title_tr", form.title_tr);
      formData.append("title_ru", form.title_ru);
      formData.append("desc_fr", form.desc_fr);
      formData.append("desc_tr", form.desc_tr);
      formData.append("desc_ru", form.desc_ru);
      formData.append("href_en", form.href_en);
      formData.append("href_tr", form.href_tr);
      formData.append("href_fr", form.href_fr);
      formData.append("href_ru", form.href_ru);

      try {
        const rst = await formApi.updatePage(formData);
        toast.success(
          "Düzenlemeler yapıldı..",
          ((window.location.href = "/document"), 1000),
          2.5
        );
      } catch (e) {
        toast.error("Düzenlemeler yapılamadı..", 1000);
      }
    }
  };

  const uploadFile = (field, data) => {
    setForm((prevState) => ({ ...prevState, [field]: data }));
  };
  
  
  const imageUpload = (data) => uploadFile('img', data);
  const pdfUpload_tr = (data) => uploadFile('href_tr', data);
  const pdfUpload_en = (data) => uploadFile('href_en', data);
  const pdfUpload_fr = (data) => uploadFile('href_fr', data);
  const pdfUpload_ru = (data) => uploadFile('href_ru', data);
  


  return (
    <Layout>
      <ToastContainer />
      <main className="home">
        <Container>
          <div className="h1">
            {isEdit ? "Dokuman Güncelleme Paneli" : "Dokuman Ekleme Paneli"}
          </div>
        </Container>

        <section id="section-one">
          <Container>
            <Row>
              <Card>
                <CardBody>
                  <div className="container mt-3 pb-5">
                    <h4>
                      {isEdit
                        ? "Dokuman Güncelleme Paneli"
                        : "Dokuman Ekleme Paneli"}{" "}
                    </h4>
                    <hr />
                    <Form onSubmit={isEdit ? onUpdateHandler : onSubmitHandler}>
                      <br />
                      <FormGroup>
                        <Label for="doc_no">
                          <b>Dokuman No</b>
                        </Label>
                        <Input
                          id="doc_no"
                          name="doc_no"
                          placeholder="Lütfen dokuman no giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.doc_no}
                          valid={validation.doc_no === "T"}
                          invalid={validation.doc_no === "F"}
                        />
                      </FormGroup>

                      <Slider imageUpload={imageUpload} data={dataSources} />

                      <br />
                      <FormGroup>
                        <Label for="title">
                          <b>Dokuman Adı (en)</b>
                        </Label>
                        <Input
                          id="title"
                          name="title"
                          placeholder="Lütfen dokuman adını giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title}
                          valid={validation.title === "T"}
                          invalid={validation.title === "F"}
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="desc">
                          <b>Dokuman İçeriği (en)</b>
                        </Label>
                        <CKEditor
                          name="desc"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.desc !== "") editor.setData(form.desc);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler("desc", editor.getData())
                          }
                        />
                        {validation.desc === "F" && (
                          <div className="val-message">
                            Bu alan boş bırakılamaz.
                          </div>
                        )}
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <Label style={{ display: "block" }} for="desc">
                          <b>Döküman Yükle (en)</b>
                        </Label>
                        <input
                          type="file"
                          id="href_en"
                          name="href_en"
                          accept="image/png, image/jpeg"
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="title_tr">
                          <b>Dokuman Adı (tr)</b>
                        </Label>
                        <Input
                          id="title_tr"
                          name="title_tr"
                          placeholder="Lütfen dokuman adını giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title_tr}
                          valid={validation.title_tr === "T"}
                          invalid={validation.title_tr === "F"}
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="desc_tr">
                          <b>Dokuman İçeriği (tr)</b>
                        </Label>
                        <CKEditor
                          name="desc_tr"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.desc_tr !== "")
                              editor.setData(form.desc_tr);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler("desc_tr", editor.getData())
                          }
                        />
                        {validation.desc_tr === "F" && (
                          <div className="val-message">
                            Bu alan boş bırakılamaz.
                          </div>
                        )}
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <Label style={{ display: "block" }} for="desc">
                          <b>Döküman Yükle (tr)</b>
                        </Label>
                        <input
                          type="file"
                          id="href_tr"
                          name="href_tr"
                          accept="image/png, image/jpeg"
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="title_fr">
                          <b>Dokuman Adı (fr)</b>
                        </Label>
                        <Input
                          id="title_fr"
                          name="title_fr"
                          placeholder="Lütfen dokuman adını giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title_fr}
                          valid={validation.title_fr === "T"}
                          invalid={validation.title_fr === "F"}
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="desc_fr">
                          <b>Dokuman İçeriği (fr)</b>
                        </Label>
                        <CKEditor
                          name="desc_fr"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.desc_fr !== "")
                              editor.setData(form.desc_fr);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler("desc_fr", editor.getData())
                          }
                        />
                        {validation.desc_fr === "F" && (
                          <div className="val-message">
                            Bu alan boş bırakılamaz.
                          </div>
                        )}
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <Label style={{ display: "block" }} for="desc">
                          <b>Döküman Yükle (fr)</b>
                        </Label>
                        <input
                          type="file"
                          id="href_fr"
                          name="href_fr"
                          accept="image/png, image/jpeg"
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="title_ru">
                          <b>Dokuman Adı (ru)</b>
                        </Label>
                        <Input
                          id="title_ru"
                          name="title_ru"
                          placeholder="Lütfen dokuman adını giriniz..."
                          type="text"
                          onChange={(e) =>
                            onChangeHandler(e.target.name, e.target.value)
                          }
                          value={form.title_ru}
                          valid={validation.title_ru === "T"}
                          invalid={validation.title_ru === "F"}
                        />
                      </FormGroup>

                      <br />
                      <FormGroup>
                        <Label for="desc_ru">
                          <b>Dokuman İçeriği (ru)</b>
                        </Label>
                        <CKEditor
                          name="desc_ru"
                          editor={ClassicEditor}
                          onReady={(editor) => {
                            if (form.desc_ru !== "")
                              editor.setData(form.desc_ru);
                          }}
                          onChange={(event, editor) =>
                            onChangeHandler("desc_ru", editor.getData())
                          }
                        />
                        {validation.desc_ru === "F" && (
                          <div className="val-message">
                            Bu alan boş bırakılamaz.
                          </div>
                        )}
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <Label style={{ display: "block" }} for="desc">
                          <b>Döküman Yükle (ru)</b>
                        </Label>
                        <input
                          type="file"
                          id="href_ru"
                          name="href_ru"
                          accept="image/png, image/jpeg"
                        />
                      </FormGroup>
                      <br />
                      <Button color="primary" type="submit">
                        {isEdit ? "Güncelle" : "Ekle"}
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default FormIndexDocument;
